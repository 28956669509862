import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  return (
      <Row className="github-row" style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <h1 style={{ paddingBottom: "20px" }}>
          Days I <strong>Code</strong>
        </h1>
        <GitHubCalendar
          // username="soumyajit4419"
          username="kiro358"
          blockSize={15}
          blockMargin={5}
          // color="#c084f5"
          fontSize={16}
        />
      </Row>
  );
}

export default Github;