import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
// import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Resume from './components/Resume';
import Footer from './components/Footer';
import Ai from './components/Ai';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/portfolio" element={<Portfolio />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/ai" element={<Ai />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    <Analytics />
    </>
  );
}

export default App;
